export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const ME_LOADING = 'ME_LOADING';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAIL = 'ME_FAIL';

export const RELOAD_ME = 'RELOAD_ME';
export const RELOAD_ME_LOADING = 'RELOAD_ME_LOADING';
export const RELOAD_ME_SUCCESS = 'RELOAD_ME_SUCCESS';
export const RELOAD_ME_FAIL = 'RELOAD_ME_FAIL';

// ------------------ //

export const GET_ASSIGNMENTS_LOADING = 'GET_ASSIGNMENTS_LOADING';
export const GET_ASSIGNMENTS_SUCCESS = 'GET_ASSIGNMENTS_SUCCESS';
export const GET_ASSIGNMENTS_FAIL = 'GET_ASSIGNMENTS_FAIL';

export const ADD_ASSIGNMENT_LOADING = 'ADD_ASSIGNMENT_LOADING';
export const ADD_ASSIGNMENT_SUCCESS = 'ADD_ASSIGNMENT_SUCCESS';
export const ADD_ASSIGNMENT_FAIL = 'ADD_ASSIGNMENT_FAIL';

export const COMPLETE_ASSIGNMENT_LOADING = 'COMPLETE_ASSIGNMENT_LOADING';
export const COMPLETE_ASSIGNMENT_SUCCESS = 'COMPLETE_ASSIGNMENT_SUCCESS';
export const COMPLETE_ASSIGNMENT_FAIL = 'COMPLETE_ASSIGNMENT_FAIL';

export const EDIT_ASSIGNMENT_LOADING = 'EDIT_ASSIGNMENT_LOADING';
export const EDIT_ASSIGNMENT_SUCCESS = 'EDIT_ASSIGNMENT_SUCCESS';
export const EDIT_ASSIGNMENT_FAIL = 'EDIT_ASSIGNMENT_FAIL';
export const CLEAR_ASSIGNMENT_ERROR = 'CLEAR_ASSIGNMENT_ERROR';

export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const EDIT_USER_LOADING = 'EDIT_USER_LOADING';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';

// ------------------ //

export const GET_CANVAS_ASSIGNMENTS_LOADING = 'GET_CANVAS_ASSIGNMENTS_LOADING';
export const GET_CANVAS_ASSIGNMENTS_SUCCESS = 'GET_CANVAS_ASSIGNMENTS_SUCCESS';
export const GET_CANVAS_ASSIGNMENTS_FAIL = 'GET_CANVAS_ASSIGNMENTS_FAIL';

export const COMPLETE_CANVAS_ASSIGNMENT_LOADING = 'COMPLETE_CANVAS_ASSIGNMENT_LOADING';
export const COMPLETE_CANVAS_ASSIGNMENT_SUCCESS = 'COMPLETE_CANVAS_ASSIGNMENT_SUCCESS';
export const COMPLETE_CANVAS_ASSIGNMENT_FAIL = 'COMPLETE_CANVAS_ASSIGNMENT_FAIL';

export const CONFIRM_CANVAS_ASSIGNMENT_LOADING = 'CONFIRM_CANVAS_ASSIGNMENT_LOADING';
export const CONFIRM_CANVAS_ASSIGNMENT_SUCCESS = 'CONFIRM_CANVAS_ASSIGNMENT_SUCCESS';
export const CONFIRM_CANVAS_ASSIGNMENT_FAIL = 'CONFIRM_CANVAS_ASSIGNMENT_FAIL';

export const UPDATE_CANVAS_ASSIGNMENT_LOADING = 'UPDATE_CANVAS_ASSIGNMENT_LOADING';
export const UPDATE_CANVAS_ASSIGNMENT_SUCCESS = 'UPDATE_CANVAS_ASSIGNMENT_SUCCESS';
export const UPDATE_CANVAS_ASSIGNMENT_FAIL = 'UPDATE_CANVAS_ASSIGNMENT_FAIL';

export const CREATE_CANVAS_ASSIGNMENT_LOADING = 'CREATE_CANVAS_ASSIGNMENT_LOADING';
export const CREATE_CANVAS_ASSIGNMENT_SUCCESS = 'CREATE_CANVAS_ASSIGNMENT_SUCCESS';
export const CREATE_CANVAS_ASSIGNMENT_FAIL = 'CREATE_CANVAS_ASSIGNMENT_FAIL';

export const SORT_ASSIGNMENTS = 'SORT_ASSIGNMENTS';
